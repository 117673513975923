<template>
    <div>
        <div class="header">
            <div class="header-header">
                <img src="../../assets/img/qianliang/auth/riLine-error-warning-line@1x.png">
                <div>温馨提示</div>
            </div>
            <div class="header-body">本服务是基于微信社群关系的应用，首次使用时，需要绑定你的微信号，获取你的社群推荐人信息</div>
        </div>
        <van-cell-group inset class="qianliang-card">
            <div class="qianliang-card-header">
                <img src="../../assets/img/qianliang/auth/iconPark-link-two@1x.png" class="qianliang-card-icon">
                <div class="qianliang-card-title">绑定微信号</div>
            </div>
            <div class="qianliang-card-body" style="display: flex;flex-direction: column;padding-left: 14px;align-items: center">
                <van-field :disabled="querys" v-model="wcId" size="small" placeholder="请去微信复制你的微信号并粘贴"
                           right-icon="search"
                           style="border: 1px solid rgba(187, 187, 187, 1);padding: 6px;margin-bottom: 10px;"/>
                <p style="color: red" v-if="!querys">此处不要输入手机号！是微信号！</p>
<!--                <p style="color: red">此处不要输入手机号！是微信号！</p>-->
                <img v-if="querys" :src="headImg" style="width:68px;height:68px;border-radius: 50%;margin: 10px 0;">
                <div style="display: flex;width:100%;margin: 10px 0;justify-content: space-evenly;">
                    <van-button style="background: #FEC919;color: #fff;border: none;width: 100px;height: 38px;" :disabled="!wcId" size="normal" @click="bind">绑定</van-button>
                    <van-button style="width: 100px;height: 38px;" size="normal" @click="cancel">取消</van-button>
                </div>
            </div>
        </van-cell-group>
    </div>
</template>

<script>
export default {
    name: "auth",
    data(){
        return {
            wcId:'',
            querys: false,
            headImg:'',
        }
    },
    created() {
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
    },
    mounted() {
        let query = this.$route.query
        console.log(query)
        if(query && query.wcId && query.headImg){
            this.wcId = query.wcId
            this.headImg = query.headImg
            this.querys = true
        } else {
            this.querys = false
        }
    },
    methods:{
        bind(){
            this.post('/member/bindWxId',{wcId:this.wcId}).then(result=>{
                if(result.respCode === "0") {
                    console.log(result.data)
                    localStorage.setItem('userInfo',JSON.stringify(result.data))
                    this.$dialog.alert({
                        title: '提示',
                        message: '绑定成功',
                    }).then(() => {
                        // 如果没有推荐人，先跳转到加群的页面
                        if(!result.data.referenceWcId){
                            this.$router.replace('/qr_code')
                            return
                        }
                        this.$router.back()
                    });
                } else {
                    this.$router.push('/qr_code')
                }
            })
        },
        cancel(){
            this.$router.back()
        }
    }
}
</script>

<style scoped>
.header{
  width: 100%;
  line-height: 20px;
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(187, 187, 187, 1);
  color: #fff;
  padding-bottom: 10px;
}
.header-header{
  padding-left: 20px;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  font-family: PingFangSC-regular;
  display: flex;
  align-items: center;
}
.header-header img{
  height: 16px;
  width: 16px;
  margin-right: 3px;
}
.header-body{
  padding-left: 31px;
  padding-right: 19px;
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
}
</style>